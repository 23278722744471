import { Code, ConnectError } from '@connectrpc/connect';
import useKeycloakAuth from './auth/useKeycloakAuth';
import { GP_ACCESS_TOKEN_COOKIE } from './auth/constants';

export default defineNuxtPlugin({
  dependsOn: ['sentry-client'],
  setup: async () => {
    const { $sentry, $i18n } = useNuxtApp();

    const authStore = useAuthStore();
    const oidcStore = useOidcStore();
    const notificationStore = useNotificationsStore();

    const route = useRoute();
    const router = useRouter();

    const cleanUrl = () => {
      setTimeout(() => {
        router.replace({
          query: {
            state: undefined,
            code: undefined,
            session_state: undefined,
            iss: undefined,
          },
        });
      }, 1000);
    };
    const { auth } = await useKeycloakAuth(cleanUrl);

    const tokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE);

    /**
     * When coming from the login callback, we have to handle the auth callback and init the keycloak-js with tokens for token refresh mechanism.
     *
     * @returns {Promise<{ provide: { auth: KeycloakAuth | null } }>}
     */
    const handleAuthCallback = async () => {
      // we have to cleanup the query params from redirect from KC on login
      await auth.init({
        onLoad: 'check-sso',
        token: oidcStore.accessToken,
        idToken: oidcStore.idToken,
        refreshToken: oidcStore.refreshToken,
      });

      return {
        provide: {
          auth,
        },
      };
    };

    /**
     * When we are authenticated on server side, also init auth for establishing the session on frontend side.
     *
     * @returns {Promise<{ provide: { auth: KeycloakAuth | null } }>}
     */
    const handleExistingSession = async () => {
      await auth.init({
        onLoad: 'check-sso',
      });

      return {
        provide: {
          auth,
        },
      };
    };

    /**
     * When we have a server error, then show the error notification on the frontend side. No more actions are necessary.
     */
    const handleServiceError = async () => {
      if (route.query.service_error != 'null') {
        notificationStore.pushNotification({
          type: 'error',
          title: $i18n.t('general/error/text'),
          message: `${$i18n.t(
            'frontend/something-went-wrong-please-try-again-later',
          )} | ${route.query.service_error}`,
          id: new Date().getTime(),
        });
      }

      cleanUrl();
    };

    try {
      if (
        // if the server provided the access token (from auth callback), init keycloak-js for token refresh mechanism
        authStore.isAuthenticated &&
        oidcStore.refreshToken != null
      ) {
        return handleAuthCallback();
      }

      if (
        // if we are authenticated on server side, also init auth for establishing the session on frontend side
        authStore.isAuthenticated ||
        // or if we have cookies, then we are also authenticated on server side
        tokenCookie.value != null ||
        // or server has returned a GRPC 16 service error (unauthorized), which indicates in most cases that the token is expired
        // try to recover the session on frontend side
        (authStore.serviceError != null &&
          (authStore.serviceError as unknown as ConnectError).code ===
            Code.Unauthenticated)
      ) {
        return handleExistingSession();
      }

      if (route.query.service_error != null) {
        // in case the error not a 'null' string, then show the error notification
        handleServiceError();
      } else if (route.fullPath === '/nuxt/callback') {
        // in case we are at the callback page, but we do not have a service error, destroy the session and redirect to the redirect query param
        authStore.user = null;
      }
    } catch (error) {
      $sentry.captureException(error);

      // if there is something wrong with the auth, then destroy the session and redirect to the home page
      const { logout } = await useKeycloakAuth(cleanUrl);
      await logout();
    }

    return {
      provide: {
        auth,
      },
    };
  },
});
